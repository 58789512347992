import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(utc)

dayjs.extend(timezone)
// Set default timezone to Asia/Kuala_Lumpur (GMT+8)
dayjs.tz.setDefault("Asia/Kuala_Lumpur")

dayjs.extend(relativeTime)

export const getRelativeTime = (date?: string | Date) => {
    if (!date) {
        return "Undefined Date/Time"
    }

    const now = dayjs()
    const notificationTime = dayjs.utc(date)
    const diffInMinutes = now.diff(notificationTime, "minute")
    const diffInHours = now.diff(notificationTime, "hour")
    const diffInDays = now.diff(notificationTime, "day")

    if (diffInMinutes < 60) {
        // Less than an hour ago
        return `${diffInMinutes}m ago`
    } else if (diffInHours < 24) {
        // Less than a day ago
        return `${diffInHours}h ago`
    } else if (diffInDays < 7) {
        // Less than a week ago
        return `${diffInDays}d ago`
    } else {
        // More than a week ago, show exact date
        return notificationTime.format("DD/MM/YYYY")
    }
}

export const formatUTC = (date: string | number | Date, format: string) => {
    return dayjs.utc(date).tz("Asia/Kuala_Lumpur").format(format)
}

export default dayjs
